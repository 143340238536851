import { API_URL } from "../constan.local";
import { user, refreshToken } from "$lib/stores";
import { decodeJWT } from "$lib/utils";
import { get } from "svelte/store";

interface IResponse {
  status: number;
  json: any;
}

type fetchType = (input: RequestInfo, init?: RequestInit) => Promise<Response>;

// GETs data from the API
async function fetchData(
  ressourceId: string,
  url: string,
  fetch: fetchType,
): Promise<IResponse> {
  try {
    const response = await fetch(API_URL + `${ressourceId}/${url}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      // http only cookies for auth:
      credentials: "include",
    });
    if (!response.ok) {
      console.warn(response.status);
      console.warn(response.statusText);

      if (response.status === 401) {
        const refreshTokenState = get(refreshToken);

        if (!refreshTokenState) return { status: 401, json: null };

        const { status, json } = await postRefreshToken(refreshTokenState);

        if (status === 401) return { status: 401, json: null };
        const newResponse = await fetch(API_URL + `${ressourceId}/${url}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });
        if (!newResponse.ok) {
          return { status: newResponse.status, json: null };
        }
        return { status: newResponse.status, json: await newResponse.json() };
      } else {
        return { status: response.status, json: null };
      }
    }
    return { status: response.status, json: await response.json() };
  } catch (error: any) {
    console.error(error.message);
    return { status: 500, json: null };
  }
}

//PATCHs data to the API
async function patchData(
  ressourceId: string,
  url: string,
  id: number | string,
  data: any,
): Promise<IResponse> {
  try {
    const response = await fetch(API_URL + `${ressourceId}/${url}/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      credentials: "include",
    });
    if (!response.ok) {
      if (response.status === 401) {
        const refreshTokenState = get(refreshToken);
        if (!refreshTokenState) return { status: 401, json: null };
        const { status, json } = await postRefreshToken(refreshTokenState);
        if (status === 401) return { status: 401, json: null };
        const newResponse = await fetch(
          API_URL + `${ressourceId}/${url}/${id}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
            credentials: "include",
          },
        );
        if (!newResponse.ok) {
          return { status: newResponse.status, json: null };
        }
        return { status: newResponse.status, json: await newResponse.json() };
      } else {
        return { status: response.status, json: null };
      }
    }
    return { status: response.status, json: await response.json() };
  } catch (error: any) {
    console.error(error.message);
    return { status: 500, json: null };
  }
}

// POSTs data to the API
async function postData(
  ressourceId: string,
  url: string,
  data: any,
): Promise<IResponse> {
  try {
    const response = await fetch(API_URL + `${ressourceId}/${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      credentials: "include",
    });
    if (!response.ok) {
      if (response.status === 401) {
        const refreshTokenState = get(refreshToken);
        if (!refreshTokenState) return { status: 401, json: null };
        const { status, json } = await postRefreshToken(refreshTokenState);
        if (status === 401) return { status: 401, json: null };
        const newResponse = await fetch(API_URL + `${ressourceId}/${url}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
          credentials: "include",
        });
        if (!newResponse.ok) {
          return { status: newResponse.status, json: null };
        }
        return { status: newResponse.status, json: await newResponse.json() };
      } else {
        return { status: response.status, json: null };
      }
    }
    return { status: response.status, json: await response.json() };
  } catch (error: any) {
    console.error(error.message);
    return { status: 500, json: null };
  }
}

// DELETEs data from the API
async function deleteData(
  ressourceId: string,
  url: string,
  id: number,
): Promise<IResponse> {
  try {
    let completeURL = `${ressourceId}/${url}/${id}`;
    if (completeURL.includes("undefined") || completeURL.includes("null")) {
      throw new Error("URL is not correct: " + completeURL);
    }
    completeURL = completeURL.replaceAll("//", "/");
    const response = await fetch(API_URL + completeURL, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    console.log(API_URL + completeURL);

    if (!response.ok) {
      if (response.status === 401) {
        const refreshTokenState = get(refreshToken);
        if (!refreshTokenState) return { status: 401, json: null };
        const { status, json } = await postRefreshToken(refreshTokenState);
        if (status === 401) return { status: 401, json: null };
        const newResponse = await fetch(API_URL + completeURL, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });
        if (!newResponse.ok) {
          return { status: newResponse.status, json: null };
        }
        return { status: newResponse.status, json: await newResponse.json() };
      } else {
        return { status: response.status, json: null };
      }
    }
    return { status: response.status, json: await response.json() };
  } catch (error: any) {
    console.error(error.message);
    return { status: 500, json: null };
  }
}

async function postRefreshToken(refreshToken: string): Promise<IResponse> {
  try {
    const response = await fetch(API_URL + "auth/refresh", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: refreshToken }),
      credentials: "include",
      cache: "no-cache",
    });
    if (!response.ok) {
      if (response.status === 401 || response.status === 500) {
        return { status: 401, json: null };
      } else {
        return { status: response.status, json: null };
      }
    }
    if (response.status === 201) {
    }
    return { status: response.status, json: null };
  } catch (error: any) {
    console.error(error.message);
    return { status: 500, json: null };
  }
}

async function getRefeshTokenFromGoogleAccessToken(
  accessTokenGoogle: string,
): Promise<IResponse> {
  try {
    const response = await fetch(API_URL + "auth/google", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: accessTokenGoogle }),
    });
    if (!response.ok) {
      if (response.status === 401) {
        return { status: 401, json: null };
      } else {
        return { status: response.status, json: null };
      }
    }
    const json = await response.json();
    return { status: response.status, json: json };
  } catch (error: any) {
    console.error(error.message);
    return { status: 500, json: null };
  }
}

async function logout() {
  try {
    const response = await fetch(API_URL + "auth/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      if (response.status === 401) {
        return { status: 401, json: null };
      } else {
        return { status: response.status, json: null };
      }
    }
    return { status: response.status, json: null };
  } catch (error: any) {
    console.error(error.message);
    return { status: 500, json: null };
  }
}

export {
  fetchData,
  patchData,
  postData,
  deleteData,
  postRefreshToken,
  getRefeshTokenFromGoogleAccessToken,
  logout,
  type IResponse,
};
